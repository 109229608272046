
.result-container {
    text-align: center;
    padding: 20px; 
}

.result-content {
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
}

.result-heading {
    color: #2c3e50;
    margin-bottom: 20px;
    font-size: 24px;
}

.result-subheading {
    color: #2c3e50;
    margin-bottom: 20px;
    font-size: 20px;
}

.result-text {
    font-size: 18px;
    line-height: 1.6;
    color: #34495e;
    margin: 10px 0;
}

.green-highlight {
    color: #10bf10;
    font-weight: bold;
    font-size: 22px;
}

.red-highlight {
    color: red;
    font-weight: bold;
    font-size: 22px;
}

.blue-highlight {
    color: #2c3e50;
    font-weight: bold;
    font-size: 22px;
}

.contact-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #10bf10;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 20px; 
}

.contact-button:hover {
    background-color: #10bf10;
}

.success-message {
    color: green;
    font-size: 18px;
    margin-top: 20px;
}

.error-message {
    color: red;
    font-size: 18px;
    margin-top: 20px;
}

.spinner-container {
    padding-top: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s ease infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.button-container {
    margin-top: 20px; 
}

.video-container {
    position: relative;
}

.video {
    aspect-ratio: 16 / 9;
    width: 50%;
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1;
}

@media (max-width: 600px) {
    .result-container {
        padding-top: 70px; 
    }

    .result-content {
        padding: 10px; 
    }

    .result-heading {
        font-size: 20px; 
    }

    .result-subheading {
        font-size: 18px; 
    }

    .result-text {
        font-size: 16px; 
    }

    .green-big-highlight, .red-highlight, .blue-highlight {
        font-size: 20px; 
    }

    .spinner-container {
        padding-top: 340px;
    }

    .video {
        aspect-ratio: 16 / 9;
        width: 90%;
    }
}
