@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@400;700;900&display=swap');

body {
  font-family: 'Heebo', sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  font-family: 'Heebo', sans-serif;
  direction: rtl;
  text-align: right;
}

h1, h2, h3, label {
  color: #0d730d; 
  font-family: 'Heebo', sans-serif;
}

h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 900; 
  text-align: center; 
}

form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px; 
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 0; 
}

.form-group {
  margin-bottom: 15px;
}

.error {
  color: red;
  font-size: 12px;
  position: absolute;
  right: 0;
  top: 100%;
  white-space: nowrap;
}

label {
  margin-bottom: 5px;
  font-weight: bold;
}

input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  text-align: right;
}

input[type="checkbox"] {
  width: 5%;
    accent-color: #0d730d; 

}

button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #0d730d; 
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0a520a; 
}



.success-message {
  margin-top: 20px;
  color: green;
  font-size: 18px;
  font-weight: bold;
}

.form-row {
  display: flex;
  justify-content: space-between;
}

.half-width {
  width: 48%;
}

@media (max-width: 600px) {
  .App {
    height: auto; 
    padding: 20px; 
  }

  h1 {
    font-size: 24px; 
    margin-top: 10px; 
    text-align: center; 
  }

  form {
    max-width: 300px; 
    width: 90%;
  }
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  outline: none;
  width: 80%;
  max-width: 500px;
  text-align: right;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.spinner-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000; 
}

.spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #0d730d; 
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}